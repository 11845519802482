
import { defineComponent } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmCountriesRow from '@/components/shared/TmCountriesRow.vue'

export default defineComponent({
  components: {
    TmCountriesRow,
    TmCountry,
    TmFlag,
  },
  setup() {
    const countries = [
      { id: 'us', name: 'United States' },
      { id: 'ee', name: 'Estonia' },
      { id: 'fr', name: 'France' },
      { id: 'dk', name: 'Denmark' },
      { id: '', name: 'Undefined country' },
    ]
    const country = { id: 'us', name: 'United States' }
    const sizes = ['xxSmall', 'xSmall', 'small', '', 'large']
    return {
      countries,
      country,
      sizes,
    }
  },
})
